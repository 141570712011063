<template>
  <v-container>
    <div class="text-center">
      <v-card
        color="box_bg"
      >
        <v-img
          caption
          src="../assets/img/upvideo_bg.webp"
          class="d-flex align-center"
        >
          <div
            class="pa-10 white--text"
          >
            <h3>{{ $t('fbspyqjj') }}</h3>
            <p>{{ $t('mtlqjl') }}</p>
          </div>
        </v-img>
        <v-card-text
          class="text-center py-0"
        >
          <v-timeline
            align-top
            dense
            class="text-left"
          >
            <v-timeline-item
              color="pink lighten-2"
              small
            >
              {{ $t('xzrhfb') }}
            </v-timeline-item>

            <v-timeline-item
              color="cyan lighten-2"
              small
            >
              <span class="orange--text text--lighten-2">{{ $t('fsytpl') }}</span>
            </v-timeline-item>

            <v-timeline-item
              color="pink lighten-2"
              small
            >
              {{ $t('scjtlink') }}
            </v-timeline-item>

            <v-timeline-item
              color="cyan lighten-2"
              small
            >
              {{ $t('bzfbrq') }}
            </v-timeline-item>

            <v-timeline-item
              color="pink lighten-2"
              small
            >
              {{ $t('mydymjl') }}
            </v-timeline-item>

            <v-timeline-item
              color="cyan lighten-2"
              small
            >
              <div class="mb-3">
                <v-btn
                  v-if="todaycount<6"
                  :disabled="!fileurl"
                  depressed
                  dark
                  outlined
                  :loading="loading"
                  color="cyan"
                  @dblclick="dblclick"
                  @click="downloadvideo"
                >
                  {{ $t('bcspxz') }}
                </v-btn>
              </div>

              <video
                v-if="fileurl"
                width="140"
                height="248"
                muted
                autoplay="autoplay"
                controls
              >
                <source
                  :src="fileurl"
                  type="video/mp4"
                >
              </video>
            </v-timeline-item>
            <v-timeline-item
              color="pink lighten-2"
              small
            >
              <v-textarea
                flat
                dense
                solo
                hide-details
                readonly
                rows="3"
                background-color="btn_bg"
                :value="comments"
                append-icon="mdi-content-copy"
                @click:append="doCopy(comments)"
              />
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>

      <v-card
        color="box_bg"
        class="mt-3"
      >
        <v-card-text>
          <div
            v-if="f1!=null"
            class="nav_bg pa-3 rounded mx-auto"
          >
            <div class="mb-2">
              <v-btn
                depressed
                fab
                x-small
                dark
                color="red accent-2"
                @click="delimg"
              >
                <v-icon color="red1">
                  mdi-delete-forever
                </v-icon>
              </v-btn>
            </div>
                
            <img
              v-if="f1==null"
              width="100"
              src="../assets/img/pic/postimg-01.jpg"
            >
            <img
              v-if="f1!=null"
              width="100"
              :src="f1"
            >
          </div>
              
          <div
            v-if="f1==null && todaycount<6"
            class="d-flex justify-center"
          >
            <v-avatar
              color="nav_bg"
              size="56"
            >
              <v-file-input
                ref="ff1"
                v-model="file1"
                hide-input
                accept="image/png, image/jpeg, image/jpg"
                prepend-icon="mdi-camera-plus"
                class="pa-0 ma-0 ml-2"
                style="flex: none!important;"
                @change="onSelectFile()"
              />
            </v-avatar>
          </div>

          <v-text-field
            v-if="todaycount<6"
            v-model="link"
            flat
            dense
            filled
            rounded
            label="Video Sharing Link"
            :hide-details="link_error?false:true"
            :error-messages="link_error"
            clearable
            color="cyan"
            background-color="nav_bg"
            prepend-inner-icon="mdi-link-variant"
            placeholder=" Video Sharing Link..."
            class="rounded my-5"
          />

          <v-btn
            depressed
            block
            dark
            color="cyan"
            :loading="loading"
            :disabled="todaycount>5"
            @dblclick="dblclick"
            @click="submitvideopubs"
          >
            {{ $t('tijiao') }}
          </v-btn>

          <v-divider class="opacity-3 my-3" />

          <v-subheader class="px-0 subtitle-2">
            {{ $t('jlck') }}
          </v-subheader>
          <v-carousel
            cycle
            height="400"
          >
            <v-carousel-item
              v-for="p in 4"
              :key="'i'+p"
              :src="require('@/assets/img/pic/upvimg-0'+p+'.jpg')"
            />
          </v-carousel>

          <v-divider class="opacity-3 my-3" />

          <v-card-title
            class="py-3 text-body-1"
          >
            {{ $t('lsjl') }}
          </v-card-title>

          <v-list
            color="box_bg"
            class="text-caption pt-0"
          >
            <div
              v-for="(item, index) in list"
              :key="'h'+index"
            >
              <v-divider class="opacity-3" />
              <v-list-item>
                <v-list-item-action>
                  {{ item.created }}
                </v-list-item-action>
                <v-list-item-content>
                  <div>
                    <img
                      width="50"
                      class="mx-1"
                      :src="item.imgs"
                    >
                  </div>
                </v-list-item-content>
                <v-list-item-action
                  v-if="item.status==0"
                  class="text-right"
                >
                  {{ $t('daishenhe') }}
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.status==1"
                  class="text-right"
                >
                  <div>
                    <div class="orange--text font-weight-medium">
                      + R$ {{ item.money }}
                    </div>
                    <span class="green--text">{{ $t('yifafang') }}</span>
                  </div>
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.status==-1"
                  class="text-right"
                >
                  <div class="text-center">
                    <div class="red--text">
                      {{ $t('weitongguo') }}
                    </div>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    <template v-if="showdialog">
      <Login
        v-model="loginreg"
        @closeloginreg="closeloginreg"
        @showforget="showforget"
      />
    </template>
    <template v-if="forget">
      <Forget
        @closeforget="closeforget"
      />
    </template>
  </v-container>
</template>
  
  <script>
  import Login from '@/components/Login.vue'
  import Forget from '@/components/Forget.vue'
  
  export default {
    components: {
      Login,
      Forget
    },
    data: () => ({
      comments: 'www.cashpg.com 👈 🙋‍♂️Gente essa plataforma da 12.77 de bônus no cadastro, Super recomendo!',
      fileurl: '',
      file: '',
      todaycount: 0,
      f1: null,
      file1: null,
      link: '',
      link_error: '',
      list: [],
      showdialog: false,
      loginreg: false,
      forget: false,
      loading: false,
    }),
    computed: {
  
    },
    watch: {
  
    },
    created() {
      this.getavideo()
    },
    mounted() {
      this.videopubs()
    },
    beforeDestroy() {
  
    },
    methods: {
      videopubs() {
        if(!this.getStorage('member_id')){
          return false
        }
        let paramObj = {
          member_id: this.getStorage('member_id'),
        }
        this.$server.videopubs(paramObj).then((response) => {
          if(response.code==200){
            this.todaycount = response.data.todaycount
            this.list = response.data.list
          }
        })
      },
      getavideo() {
        let paramObj = {
          member_id: this.getStorage('member_id'),
        }
        this.$server.getavideo(paramObj).then((response) => {
          if(response.code==200){
            this.fileurl = response.data.fileurl
            this.file = response.data.file
          }
        })
      },
      downloadvideo() {
        location.href = process.env.VUE_APP_API_BASE_URL + 'index/downloadvideo?filename=' + this.file;
      },
      delimg() {
        this.file1 = null
        this.f1 = null
      },
      onSelectFile() {
        if(!this.getStorage('member_id')){
          this.showloginreg('login')
          return false
        }
        if(this.file1.size>20000000){
          this.file1 = null
          this.$snackbar.error('O tamanho da imagem deve ser inferior a 20 MB')
          return false
        }
        let paramObj = {
          member_id: this.getStorage('member_id'),
          file: this.file1
        }
        this.$server.uploadvideopubs(paramObj).then((response) => {
          if(response.code==200){
            this.f1 = response.data.url
          }else{
            this.file1 = null
            this.f1 = null
            this.$snackbar.error(response.msg)
          }
        })
      },
      submitvideopubs() {
        this.loading = true
        if(this.f1==null){
          this.$snackbar.error(this.$t('zs1zt'))
          this.loading = false
          return false
        }
        if(!this.link){
          this.link_error = this.$t('tgljbnwk')
          this.loading = false
          return false
        }
        if(!(/^https?:\/\/.+/.test(this.link))){
          this.link_error = this.$t('ljgscw')
          this.loading = false
          return false
        }
        this.link_error = ''
        let paramObj = {
          member_id: this.getStorage('member_id'),
          imgs: this.f1,
          link: this.link,
        }
        this.$server.submitvideopubs(paramObj).then((response) => {
          if(response.code==200){
            this.file1 = null
            this.f1 = null
            this.link = ''
            this.link_error = ''
            this.videopubs()
          }else{
            if(response.code==936){
              this.link_error = this.$t('tgljycz')
            }
          }
          this.loading = false
        })
      },
      showloginreg(tab) {
        this.loginreg = tab
        this.showdialog = true
      },
      closeloginreg() {
        this.loginreg = false
        this.showdialog = false
      },
      showforget() {
        this.forget = true
      },
      closeforget() {
        this.forget = false
      },
      dblclick() {
        console.log('dblclick')
      },
      doCopy: function (text) {
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('fzcg'))
        }, function (e) {
          console.log(e)
        })
      },
    }
  
  }
  </script>